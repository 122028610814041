// src/Pages/Lessons.js
import React, { useState, useEffect } from 'react';
import './lessons.css'
import ProfileNavbar from '../components/ProfileNavbar';  

const Lessons = () => {
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [mathLessons, setMathLessons] = useState([]);
  const [englishLessons, setEnglishLessons] = useState([]);
  const [scienceLessons, setScienceLessons] = useState([]);
  const [historyLessons, setHistoryLessons] = useState([]);

  

  useEffect(() => {
    // Fetch lessons from localStorage on component mount
    const storedMathLessons = JSON.parse(localStorage.getItem('mathLessons')) || [];
    setMathLessons(storedMathLessons);

    const storedEnglishLessons = JSON.parse(localStorage.getItem('englishLessons')) || [];
    setEnglishLessons(storedEnglishLessons);

    const storedScienceLessons = JSON.parse(localStorage.getItem('scienceLessons')) || [];
    setScienceLessons(storedScienceLessons);

    const storedHistoryLessons = JSON.parse(localStorage.getItem('historyLessons')) || [];
    setHistoryLessons(storedHistoryLessons);
  }, []);

  const handleSelectLesson = (lesson) => {
    setSelectedLesson(lesson);
  };

  // Function to handle adding lessons for each subject
  const handleAddLesson = (subject) => {
    const newLesson = { title: '', content: '' };
    switch (subject) {
      case 'math':
        setMathLessons([...mathLessons, newLesson]);
        localStorage.setItem('mathLessons', JSON.stringify([...mathLessons, newLesson]));
        break;
      case 'english':
        setEnglishLessons([...englishLessons, newLesson]);
        localStorage.setItem('englishLessons', JSON.stringify([...englishLessons, newLesson]));
        break;
      case 'science':
        setScienceLessons([...scienceLessons, newLesson]);
        localStorage.setItem('scienceLessons', JSON.stringify([...scienceLessons, newLesson]));
        break;
      case 'history':
        setHistoryLessons([...historyLessons, newLesson]);
        localStorage.setItem('historyLessons', JSON.stringify([...historyLessons, newLesson]));
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: '5%' }} className="lessons-page">
      {/* Profile Navbar */}
      

      {/* Subjects on Left */}
      <div className="subjects-container">
        <div className="subject-box">
          <h2>Math Lessons</h2>
          {mathLessons.map((lesson, index) => (
            <div key={index} className="lesson" onClick={() => handleSelectLesson(lesson)}>
              <h3>{lesson.title}</h3>
              <p>{lesson.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddLesson('math')}>Add Math Lesson</button>
        </div>
        <div className="subject-box">
          <h2>English Lessons</h2>
          {englishLessons.map((lesson, index) => (
            <div key={index} className="lesson" onClick={() => handleSelectLesson(lesson)}>
              <h3>{lesson.title}</h3>
              <p>{lesson.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddLesson('english')}>Add English Lesson</button>
        </div>
      </div>

      {/* Viewing Area */}
      <div className="viewing-area">
      <ProfileNavbar /> 
        {selectedLesson && (
          <div className="selected-lesson">
            <h2>{selectedLesson.title}</h2>
            <p>{selectedLesson.content}</p>
          </div>
        )}
      </div>

      {/* Subjects on Right */}
      <div className="subjects-container">
        <div className="subject-box">
          <h2>Science Lessons</h2>
          {scienceLessons.map((lesson, index) => (
            <div key={index} className="lesson" onClick={() => handleSelectLesson(lesson)}>
              <h3>{lesson.title}</h3>
              <p>{lesson.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddLesson('science')}>Add Science Lesson</button>
        </div>
        <div className="subject-box">
          <h2>History Lessons</h2>
          {historyLessons.map((lesson, index) => (
            <div key={index} className="lesson" onClick={() => handleSelectLesson(lesson)}>
              <h3>{lesson.title}</h3>
              <p>{lesson.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddLesson('history')}>Add History Lesson</button>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
