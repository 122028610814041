// src/api.js
import axiosInstance from './utils/axiosInstance';
import axios from 'axios';

// Authentication API URL
const AUTH_API_URL = 'https://llbackend.onrender.com/api/auth';

// Posts API URL
const POSTS_API_URL = 'https://llbackend.onrender.com/api/posts';

// Other API URLs
const PROJECTS_API_URL = 'http://localhost:5000/api/projects';
const LESSONS_API_URL = 'http://localhost:5000/api/lessons';
const ACTIVITIES_API_URL = 'http://localhost:5000/api/activities';
const RESOURCES_API_URL = 'http://localhost:5000/api/resources';

export const register = async (userData) => { // Done  //
  try {
    const response = await axiosInstance.post(`${AUTH_API_URL}/register`, userData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const login = async (userData) => { // Done  //
  try {
    const response = await axiosInstance.post(`${AUTH_API_URL}/login`, userData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createPost = async (postData) => {
  try {
    const response = await axiosInstance.post(POSTS_API_URL, postData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPosts = async () => {
  try {
    const response = await axios.get(POSTS_API_URL);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getCurrentUser = async () => {
  const response = await axiosInstance.get(`${AUTH_API_URL}/me`);
  return response.data;
};


export const addComment = async (postId, commentData) => {
  const response = await axiosInstance.post(`${POSTS_API_URL}/${postId}/comments`, commentData);
  return response.data;
};

export const getProjects = async () => {
  const response = await axiosInstance.get(PROJECTS_API_URL);
  return response.data;
};

export const getLessons = async () => {
  const response = await axiosInstance.get(LESSONS_API_URL);
  return response.data;
};

export const getActivities = async () => {
  const response = await axiosInstance.get(ACTIVITIES_API_URL);
  return response.data;
};

export const getResources = async () => {
  const response = await axiosInstance.get(RESOURCES_API_URL);
  return response.data;
};
