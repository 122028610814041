// src/components/ProfileNavbar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './ProfileNavbar.css';

const ProfileNavbar = () => {
  return (
    <div className="profile-navbar">
      <NavLink to="/Profile" activeClassName="Profile">Your Stuff</NavLink>
      <NavLink to="/projects" activeClassName="active">Projects</NavLink>
      <NavLink to="/lessons" activeClassName="active">Lessons</NavLink>
      <NavLink to="/activities" activeClassName="active">Activities</NavLink>
      <NavLink to="/" activeClassName="/">Logout</NavLink>
      
    </div>
  );
};

export default ProfileNavbar;
