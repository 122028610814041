// src/pages/Feed.js
import React, { useEffect, useState } from 'react';
import { getPosts } from '../api';
import './Feed.css';

const Feed = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts()
      .then(setPosts)
      .catch((error) => console.error('Error fetching posts:', error));
  }, []);

  return (
    <div className="feed">
      {posts.map((post) => (
        <div key={post._id} className="post">
          <h3>{post.title}</h3>
          <p>{post.content}</p>
          <p><em>Posted by: {post.author.name}</em></p>
        </div>
      ))}
    </div>
  );
};

export default Feed;



