// src/Pages/Lessons.js
import React, { useState, useEffect } from 'react';
import './lessons.css';
import ProfileNavbar from '../components/ProfileNavbar';  

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [artProjects, setArtProjects] = useState([]);
  const [scienceProjects, setScienceProjects] = useState([]);
  const [historyProjects, setHistoryProjects] = useState([]);
  const [otherProjects, setOtherProjects] = useState([]);

  useEffect(() => {
    // Fetch projects from localStorage on component mount
    const storedArtProjects = JSON.parse(localStorage.getItem('artProjects')) || [];
    setArtProjects(storedArtProjects);

    const storedScienceProjects = JSON.parse(localStorage.getItem('scienceProjects')) || [];
    setScienceProjects(storedScienceProjects);

    const storedHistoryProjects = JSON.parse(localStorage.getItem('historyProjects')) || [];
    setHistoryProjects(storedHistoryProjects);

    const storedOtherProjects = JSON.parse(localStorage.getItem('otherProjects')) || [];
    setOtherProjects(storedOtherProjects);
  }, []);

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  // Function to handle adding projects for each category
  const handleAddProject = (category) => {
    const newProject = { title: '', content: '' };
    switch (category) {
      case 'art':
        setArtProjects([...artProjects, newProject]);
        localStorage.setItem('artProjects', JSON.stringify([...artProjects, newProject]));
        break;
      case 'science':
        setScienceProjects([...scienceProjects, newProject]);
        localStorage.setItem('scienceProjects', JSON.stringify([...scienceProjects, newProject]));
        break;
      case 'history':
        setHistoryProjects([...historyProjects, newProject]);
        localStorage.setItem('historyProjects', JSON.stringify([...historyProjects, newProject]));
        break;
      case 'other':
        setOtherProjects([...otherProjects, newProject]);
        localStorage.setItem('otherProjects', JSON.stringify([...otherProjects, newProject]));
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: '5%' }} className="projects-page">
      

      {/* Projects on Left */}
      <div className="subjects-container">
        <div className="subject-box">
          <h2>Art Projects</h2>
          {artProjects.map((project, index) => (
            <div key={index} className="project" onClick={() => handleSelectProject(project)}>
              <h3>{project.title}</h3>
              <p>{project.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddProject('art')}>Add Art Project</button>
        </div>
        <div className="subject-box">
          <h2>Science Projects</h2>
          {scienceProjects.map((project, index) => (
            <div key={index} className="project" onClick={() => handleSelectProject(project)}>
              <h3>{project.title}</h3>
              <p>{project.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddProject('science')}>Add Science Project</button>
        </div>
      </div>

      {/* Viewing Area */}
      <div className="viewing-area">
      <ProfileNavbar />
        {selectedProject && (
          <div className="selected-project">
            <h2>{selectedProject.title}</h2>
            <p>{selectedProject.content}</p>
          </div>
        )}
      </div>

      {/* Projects on Right */}
      <div className="subjects-container">
        <div className="subject-box">
          <h2>History Projects</h2>
          {historyProjects.map((project, index) => (
            <div key={index} className="project" onClick={() => handleSelectProject(project)}>
              <h3>{project.title}</h3>
              <p>{project.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddProject('history')}>Add History Project</button>
        </div>
        <div className="subject-box">
          <h2>Other Projects</h2>
          {otherProjects.map((project, index) => (
            <div key={index} className="project" onClick={() => handleSelectProject(project)}>
              <h3>{project.title}</h3>
              <p>{project.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddProject('other')}>Add Other Project</button>
        </div>
      </div>
    </div>
  );
};

export default Projects;
