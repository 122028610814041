// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Home from './Pages/Home';
import Profile from './Pages/Profile';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import Navbar from './components/Navbar';
import Feed from './Pages/Feed';
import Lessons from './Pages/Lessons';
import Projects from './Pages/Projects';
import Activities from './Pages/Activities';

function App() {
  

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/feed" element={<Feed />} />
        <Route path="/profile" element={ <Profile /> } />
        <Route path="/lessons" element={<Lessons />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Activities" element={<Activities />} />
      </Routes>
    </Router>
  );
}

export default App;
