import React, { useContext, useEffect, useState } from 'react';
import './profile.css';
import { getCurrentUser, getPosts, getProjects, getLessons, getActivities, getResources } from '../api';  // Adjust the path as necessary
import ProfileNavbar from '../components/ProfileNavbar';  
import NewPost from './NewPost'
import AuthContext from '../context/Authcontext';



const Profile = () => {
  const { user } = useContext(AuthContext);
  const [, setUser] = useState(null);
  const [feed, setFeed] = useState([]);
  const [projects, setProjects] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [activities, setActivities] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    // Fetch the current user's data
    getCurrentUser().then(setUser).catch(console.error);

    // Fetch the feed data
    getPosts().then(setFeed).catch(console.error);

    // Fetch the user's projects
    getProjects().then(setProjects).catch(console.error);

    // Fetch the user's lessons
    getLessons().then(setLessons).catch(console.error);

    // Fetch the user's activities
    getActivities().then(setActivities).catch(console.error);

    // Fetch the user's resources
    getResources().then(setResources).catch(console.error);
  }, []);

  // Callback to handle new post creation
  const handlePostCreated = (newPost) => {
    setFeed([newPost, ...feed]);  // Add new post to the beginning of the feed
  };

  
  return (
    <div style={{ marginTop: '5%' }} className="profile-page">
      
      <div className="sidebar left">
        <div className="box">
          <h3>My Projects</h3>
          <ul>
            {projects.map(project => (
              <li key={project.id}>{project.name}</li>
            ))}
          </ul>
        </div>
        <div className="box">
          <h3>My Lessons</h3>
          <ul>
            {lessons.map(lesson => (
              <li key={lesson.id}>{lesson.title}</li>
            ))}
          </ul>
        </div>
      </div>
      
      <div className="main-content">
        <h1>Your Feed Below</h1>
        <ProfileNavbar />
        <NewPost onPostCreated={handlePostCreated} />  {/* New post form */}
        <div className="feed">
          {feed.map(post => (
            <div key={post.id} className="post">
              <h3>{post.title}</h3>
              <p>{post.content}</p>
            </div>
          ))}
        </div>
      </div>
      
      <div className="sidebar right">
        <div className="box">
          <h3>My Activity</h3>
          <ul>
            {activities.map(activity => (
              <li key={activity.id}>{activity.description}</li>
            ))}
          </ul>
        </div>
        <div className="box">
          <h3>My Resources</h3>
          <ul>
            {resources.map(resource => (
              <li key={resource.id}>{resource.name}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Profile;

