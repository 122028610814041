import React, { useState } from 'react';
import { register } from '../api';
import { useNavigate } from 'react-router-dom';
import './signup.css';


const Signup = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await register(userData);
      setSuccess('Registration successful!');
      setError('');
      console.log(response); // Optional: Log the response
      navigate('/login');
    } catch (error) {
      setError(error.msg || 'Error registering');
      setSuccess('');
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-header">
        <h2>Join Us!</h2>
        <p>Create an account to start connecting</p>
      </div>
      <div className="signup-box">
        <h2>Signup</h2>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {success && <div style={{ color: 'green' }}>{success}</div>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="name"
            name="name"
            value={userData.name}
            onChange={handleChange}
            className="signup-input"
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            className="signup-input"
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={userData.password}
            onChange={handleChange}
            className="signup-input"
          />
          <button type="submit" className="signup-button">Signup</button>
        </form>
      </div>
    </div>
  );
};

export default Signup;

