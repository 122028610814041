import React from 'react';
import './home.css';
import LL from '../images/LL.jpg';

const Home = () => {
  return (
    <div style={{ marginTop: '6%' }} className="home-container">
      <div className="header">
        <h1>Welcome to The Learning LightHouse</h1>
        <p>Connect with other Homeschool familys and share Lessons.</p>
      </div>
      
      <div className="content">
        <div className="image-placeholder">
          <img src={LL} alt="Welcome" />
        </div>
      </div>
    </div>
  );
};

export default Home;
