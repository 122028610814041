import React, { useState, useEffect } from 'react';
import './lessons.css';
import ProfileNavbar from '../components/ProfileNavbar';  

const Activities = () => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [mathActivities, setMathActivities] = useState([]);
  const [englishActivities, setEnglishActivities] = useState([]);
  const [scienceActivities, setScienceActivities] = useState([]);
  const [historyActivities, setHistoryActivities] = useState([]);

  useEffect(() => {
    // Fetch activities from localStorage on component mount
    const storedMathActivities = JSON.parse(localStorage.getItem('mathActivities')) || [];
    setMathActivities(storedMathActivities);

    const storedEnglishActivities = JSON.parse(localStorage.getItem('englishActivities')) || [];
    setEnglishActivities(storedEnglishActivities);

    const storedScienceActivities = JSON.parse(localStorage.getItem('scienceActivities')) || [];
    setScienceActivities(storedScienceActivities);

    const storedHistoryActivities = JSON.parse(localStorage.getItem('historyActivities')) || [];
    setHistoryActivities(storedHistoryActivities);
  }, []);

  const handleSelectActivity = (activity) => {
    setSelectedActivity(activity);
  };

  // Function to handle adding activities for each subject
  const handleAddActivity = (subject) => {
    const newActivity = { title: '', content: '' };
    switch (subject) {
      case 'math':
        setMathActivities([...mathActivities, newActivity]);
        localStorage.setItem('mathActivities', JSON.stringify([...mathActivities, newActivity]));
        break;
      case 'english':
        setEnglishActivities([...englishActivities, newActivity]);
        localStorage.setItem('englishActivities', JSON.stringify([...englishActivities, newActivity]));
        break;
      case 'science':
        setScienceActivities([...scienceActivities, newActivity]);
        localStorage.setItem('scienceActivities', JSON.stringify([...scienceActivities, newActivity]));
        break;
      case 'history':
        setHistoryActivities([...historyActivities, newActivity]);
        localStorage.setItem('historyActivities', JSON.stringify([...historyActivities, newActivity]));
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ marginTop: '5%' }} className="activities-page">
      

      {/* Subjects on Left */}
      <div className="subjects-container">
        <div className="subject-box">
          <h2>Math Activities</h2>
          {mathActivities.map((activity, index) => (
            <div key={index} className="activity" onClick={() => handleSelectActivity(activity)}>
              <h3>{activity.title}</h3>
              <p>{activity.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddActivity('math')}>Add Math Activity</button>
        </div>
        <div className="subject-box">
          <h2>English Activities</h2>
          {englishActivities.map((activity, index) => (
            <div key={index} className="activity" onClick={() => handleSelectActivity(activity)}>
              <h3>{activity.title}</h3>
              <p>{activity.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddActivity('english')}>Add English Activity</button>
        </div>
      </div>

      {/* Viewing Area */}
      <div className="viewing-area">
      <ProfileNavbar />
        {selectedActivity && (
          <div className="selected-activity">
            <h2>{selectedActivity.title}</h2>
            <p>{selectedActivity.content}</p>
          </div>
        )}
      </div>

      {/* Subjects on Right */}
      <div className="subjects-container">
        <div className="subject-box">
          <h2>Science Activities</h2>
          {scienceActivities.map((activity, index) => (
            <div key={index} className="activity" onClick={() => handleSelectActivity(activity)}>
              <h3>{activity.title}</h3>
              <p>{activity.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddActivity('science')}>Add Science Activity</button>
        </div>
        <div className="subject-box">
          <h2>History Activities</h2>
          {historyActivities.map((activity, index) => (
            <div key={index} className="activity" onClick={() => handleSelectActivity(activity)}>
              <h3>{activity.title}</h3>
              <p>{activity.content}</p>
            </div>
          ))}
          <button onClick={() => handleAddActivity('history')}>Add History Activity</button>
        </div>
      </div>
    </div>
  );
};

export default Activities;
