// src/pages/Login.js
import React, { useState, useContext } from 'react';
import { login } from '../api';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import AuthContext from '../context/Authcontext';

const Login = () => {
  const [userData, setUserData] = useState({ email: '', password: '' });
  const { setUser } = useContext(AuthContext);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(userData);
      localStorage.setItem('token', response.token);  // Save the token for authenticated routes
      setUser(response.user);
      setSuccess('Login successful!');
      setError('');
      console.log(response); // Optional: Log the response
      navigate('/profile');  // Redirect to profile page or another authenticated page
    } catch (err) {
      setError(err.message || 'Error logging in');
      setSuccess('');
    }
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <h2>Welcome Back!</h2>
        <p>Please login to continue</p>
      </div>
      <div className="login-box">
        <h2>Login</h2>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        {success && <div style={{ color: 'green' }}>{success}</div>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={userData.password}
            onChange={handleChange}
            className="login-input"
          />
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
